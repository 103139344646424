<template>
  <div
    data-critters-container
    class="sub-h relative"
  >
    <div
      class="container d-flex sub-h__wrap justify-between relative flex-column"
    >
      <div class="sub-h__main flex-grow">
        <d-breadcrumbs
          :items="crumbsItems && crumbsItems.length ? crumbsItems : []"
          class="hide"
        />
        <div class="sub-h__headline d-flex justify-between">
          <h1
            v-if="h1"
            class="headline"
          >
            {{
              textReplace(h1, {
                "%FULL_ADDRESS%": " ",
                "%CITY%": " ",
                "\\%CITY_LOC%": "\\ ",
                "%CITY_LOC%": " ",
                "%STREET%": " ",
                " ": "%SPLIT%",
              })
                .trim()
                .replace(/\\\\/g, "\n")
                .replace(/\\/g, "\n")
                .replace(/\_/g, "&nbsp;")
                .replace(/,$/g, "")
                .trim()
            }}
            <span
              v-if="
                (h1.includes('%STREET%'))
              "
              class="headline-5"
            >{{
              textAggReplace(h1.replace(/[^%]*(%[^%]*%.*%[^%]*%)/g, `$1`), getAddressText)
            }}</span><span
              v-else-if="
                h1.includes('%FULL_ADDRESS%') || h1.includes('%CITY_LOC%')
              "
            ><span
              class="pointer"
              @click="showDialogCity"
            >{{
              getAddressText.cityOnlyLoc
            }}</span></span>
          </h1>
        </div>

        <p
          class="sub-h__promo-text title"
          v-html="h2"
        />
        <div
          v-if="!disabledPortal"
          class="sub-h__check-bg relative"
        >
          <div
            class="sub-h__check d-inline-flex align-center gray-p--bg"
            @click="openDialogAddress"
          >
            <span
              class="text-no-wrap d-flex align-center sub-h__check-text"
              :class="{ 'gray-dark--text': !houseUrl && !streetBoolean }"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/pin_large.svg"
                alt="pin"
              >
              {{
                houseUrl
                  ? getAddressText.houseAddress
                  : streetBoolean
                    ? getAddressText.streetOnly
                    : "Введите улицу и номер дома"
              }}</span>
          </div>
          <div
            class="sub-h__check--btn d-flex primary--bg align-center justify-center"
            @click.stop="showProviders(false)"
          >
            <img
              loading="lazy"
              src="@/assets/images/useful/search-thin.svg"
              alt="search"
            >
          </div>
        </div>
        <div
          v-else
          class="sub-h__checkpc-bg d-inline-flex align-center relative"
          :class="{ active: focused, shadow: shadow }"
          @mousedown="setFocused(false)"
        >
          <div
            class="sub-h__checkpc white--bg"
            :class="{ 'is-focus': street && !selectedHouse, 'active': focused }"
            @mousedown.stop
          >
            <div class="sub-h__checkpc-search">
              <form
                name="form"
                autocomplete="off"
                @submit.prevent
              >
                <small
                  v-show="shadow"
                  class="subhead-3 gray-dark--text sub-h__checkpc-tooltip"
                >Населённый пункт, улица, дом</small>
                <img
                  loading="lazy"
                  class="sub-h__checkpc-pin"
                  src="@/assets/images/svg/pin_large.svg"
                  alt="pin"
                >
                <input
                  id="sub-h__checkpc-textarea"
                  ref="addressRef"
                  autocomplete="honorific-suffix"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  :value="street"
                  rows="1"
                  maxlength="100"
                  type="text"
                  :placeholder="
                    streetBoolean
                      ? 'Укажите номер дома'
                      : 'Введите улицу и номер дома'
                  "
                  class="sub-h__checkpc-textarea"
                  data-test="input-search-by-address"
                  :class="{ 'sub-h__checkpc-textarea-popper': street }"
                  @input="input"
                  @keydown="keyDown"
                  @focus="setFocused(true)"
                >
                <div
                  :class="{ hide: shadow }"
                  class="sub-h__checkpc--btn primary--bg white--text fwm d-flex align-center justify-center"
                  @click.stop="showProviders(true)"
                >
                  Найти провайдеров
                </div>
              </form>
              <img
                v-if="street"
                :class="{ active: !shadow }"
                class="sub-h__checkpc-clear d-flex"
                loading="lazy"
                src="~/assets/images/svg/input-clear.svg"
                alt="logo"
                @click="clearStreet"
              >
              <ClientOnly>
                <div
                  class="sub-h__checkpc-popper d-flex flex-column"
                  :class="{
                    'wrong-adress': wrongAddress || isError,
                    'show':
                      (street
                        && !selectedHouse
                        && street !== getAddressText.houseAddress
                        && !(streetBoolean && street === streetAddress)
                        && street.trim() !== itemTitle(selectedCity))
                      || (shadow && (recentSearchShow?.length)),
                  }"
                >
                  <div
                    v-if="
                      !loading
                        && (!street
                          || street.trim() === itemTitle(selectedCity)
                          || (!responseList.address_objects.length
                            && !responseList.houses.length))
                    "
                    class="sub-h__tips flex-grow d-flex flex-column"
                  >
                    <div
                      v-if="recentSearchShow?.length"
                      class="sub-h__recent flex-grow d-flex flex-column"
                    >
                      <p class="title-5">
                        Вы искали:
                      </p>

                      <button
                        v-for="(item, index) in recentSearchShow.filter(
                          (el, i) => i < 3,
                        )"
                        :key="item.houseUrl + index"
                        class="sub-h__checkpc-btn justify-center d-flex flex-column"
                        data-test="hint"
                        @click.prevent="chooseRecentHouse(item)"
                      >
                        <span class="relative d-flex align-center">
                          <img
                            loading="lazy"
                            src="@/assets/images/svg/search-thin.svg"
                            class="d-flex sub-h__checkpc-btn-icon"
                            alt="search"
                            style="width: 20px; height: 20px; opacity: .3; left: -30px;"
                          >
                          <span
                            class="sub-h__checkpc-btn-title secondary-dark--text title-5"
                          >{{ item.name }}</span>
                        </span>
                      </button>
                      <button
                        class="sub-h__recent-clear gray-dark--text d-flex align-center"
                        @click="clearRecentSearch"
                      >
                        <span class="mask-icon mask-size-lg mask-trash" />
                        Удалить все
                      </button>
                    </div>
                  </div>
                  <div
                    v-else
                    ref="dragPopper"
                    class="sub-h__checkpc-res"
                    @scroll="drag"
                  >
                    <template v-if="loading">
                      <div
                        v-for="item in 20"
                        :key="'load' + item"
                        class="sub-h__checkpc-btn justify-center d-flex flex-column"
                        data-test="hint"
                      >
                        <VSkeleton
                          style="width: 240px; height: 28px; max-height: 28px;
                            border-radius: 4px;"
                          :style="
                            !selectedStreet
                              ? 'max-width: 70%'
                              : 'max-width: 100px'
                          "
                        />
                        <VSkeleton
                          v-if="!selectedStreet"
                          style="
                            width: 120px;
                            max-height: 12px;
                            height: 12px;
                            border-radius: 4px;
                            max-width: 40%;
                            margin-top: 4px;
                          "
                        />
                      </div>
                    </template>
                    <template v-else>
                      <button
                        :is="item.in_sitemap ? 'a' : 'button'"
                        v-for="(item, index) in responseList.houses"
                        :key="item.id + index"
                        class="sub-h__checkpc-btn justify-center d-flex flex-column"
                        data-test="hint"
                        @click.prevent="chooseHouse(item)"
                      >
                        <span class="relative d-flex align-center">
                          <img
                            loading="lazy"
                            src="@/assets/images/svg/search-thin.svg"
                            class="d-flex sub-h__checkpc-btn-icon"
                            alt="search"
                            style="width: 24px; height: 24px; opacity: .3"
                          >
                          <span
                            class="sub-h__checkpc-btn-title"
                            v-html="addressWithStrong(item.number)"
                          />
                        </span>
                        <small
                          v-if="item.parents_tooltip"
                          class="address-modal__btn-subtitle gray-dark--text subhead-6"
                        >{{ item.parents_tooltip }}</small>
                      </button>
                      <button
                        v-for="(item, index) in responseList.address_objects"
                        :key="item.fias_id + index"
                        class="sub-h__checkpc-btn justify-center d-flex flex-column"
                        data-test="hint"
                        @click="chooseAddress(item)"
                      >
                        <span
                          class="sub-h__checkpc-btn-title"
                          v-html="addressWithStrong(itemTitle(item))"
                        />
                        <small
                          v-if="item.parents_tooltip"
                          class="address-modal__btn-subtitle gray-dark--text subhead-6"
                        >{{ item.parents_tooltip }}</small>
                      </button>

                      <div
                        v-if="loadingMoreSkeleton"
                        class="sub-h__checkpc-btn justify-center d-flex flex-column"
                        data-test="hint"
                      >
                        <VSkeleton
                          style="width: 240px; max-height: 28px; height: 28px"
                          :style="
                            !selectedStreet
                              ? 'max-width: 70%'
                              : 'max-width: 100px'
                          "
                        />
                        <VSkeleton
                          v-if="!selectedStreet"
                          style="
                              width: 120px;
                              height: 12px;
                              max-height: 12px;
                              max-width: 40%;
                              margin-top: 4px;
                            "
                        />
                      </div>
                      <p
                        v-if="
                          enableInfinite === false || selectedStreet
                            ? responseList.houses.length >= totalItems
                            : responseList.address_objects.length >= totalItems
                        "
                        class="text-center"
                        style="padding: 20px 0"
                      >
                        Продолжите ввод, если не нашли в этом списке
                      </p>
                    </template>
                  </div>
                </div>
              </ClientOnly>
            </div>
          </div>
        </div>
        <div
          v-if="configShowMap"
          class="d-flex"
          style="max-width: 860px"
        >
          <button
            class="sub-h__showMap title-5 white--text d-flex align-center"
            @click="showMap"
          >
            <span class="sub-h__showMap-pin" />
            Выбрать на карте
          </button>
        </div>
        <div
          v-if="!streetBoolean && showBenefits"
          class="sub-h__benefits white--text d-flex"
        >
          <div class="sub-h__benefits-item">
            <img
              loading="lazy"
              src="@/assets/images/useful/percent.svg"
              alt="percent"
            >
            <p class="sub-h__benefits-title title-2">
              Выгодно
            </p>
            <p class="sub-h__benefits-descr subhead-3">
              Собрали лучшие тарифы и акции от более чем 50 провайдеров
            </p>
          </div>
          <div class="sub-h__benefits-item">
            <img
              loading="lazy"
              src="@/assets/images/useful/rocket.svg"
              alt="rocket"
            >
            <p class="sub-h__benefits-title title-2">
              Быстро
            </p>
            <p class="sub-h__benefits-descr subhead-3">
              Поиск провайдеров, сравнение и заказ подключения на одном сайте
            </p>
          </div>
        </div>

        <slot />
      </div>
    </div>
    <LazyVDialog
      v-model="showModalStreets"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalStreetsSelect
        @close="showModalStreets = false"
        @select-street="selectStreet"
      />
    </LazyVDialog>
    <LazyVDialog
      v-model="showDialogAddress"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
    >
      <LazyModalAddressCheckAdditional
        :redirect="true"
        title=""
        :new-window="false"
        :w-streets="true"
        :wo-focus="false"
        :w-focus="true"
        @close="showDialogAddress = false"
        @close-all="showDialogAddress = false"
      />
    </LazyVDialog>
  </div>
</template>

<script lang="ts" setup>
import { counter, debounce, textAggReplace, textReplace } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import type { BaseAddressObject, House } from '~/api'
import { scrollToBlock } from '~/composible/scrollToBlock'
import { recentSearchFn } from '~/composible/recentSearch'
import { getAddresLocation } from '~/composible/getAddresLoc'

const props = defineProps({
  streetBoolean: {
    type: Boolean,
    default: false,
  },
  crumbsItems: {
    type: Array,
  },
  h1: {},
  h2: {},
  showBenefits: {
    type: Boolean,
    default: true,
  },
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const tariffsStore = useTariffs()
const disabledPortal = computed(() => mainStore.disabledPortal)
const getAddressText = computed(() => cityStore.getAddressText)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })
const showDialogAddress = ref(false)
const currentCity = computed(() => cityStore.getCity)
const currentStreet = computed(() => cityStore.getDataStreet)
const houseUrl = computed(() => cityStore.getHouseUrl)
const configShowMap = computed(() => mainStore.getConfigShowMap)
const domain = computed(() => mainStore.getDomain)
const log = ref([])
const mounted = ref(false)
const showModalStreets = ref(false)
const $router = useRouter()
const $route = useRoute()
const $gtm = useGtm()
const recentSearch = computed(() => tariffsStore.getRecentSearch)
const recentSearchShow = computed(() =>
  recentSearch.value.filter(el => el.houseUrl !== houseUrl.value),
)
const street = ref('')
const enableInfinite = ref<boolean | undefined>()
const totalItems = ref<number | undefined>()
const eventInput = ref(true)
const needFocus = ref(false)
const focused = ref(false)
const shadow = ref(false)
const loading = ref(false)
const loadingValidate = ref()
const loadingMore = ref(false)
const loadingMoreSkeleton = ref(false)
const dragPopper = ref()
const isError = ref(false)
const badCheck = ref(false)
const houseEmpty = ref(false)
const updateStreet = ref(false)
const addressRef = ref()
const selectedCity = ref()
const selectedHouse = ref<House>()
const selectedStreet = ref<BaseAddressObject>()
const wrongAddress = computed(() => {
  return (
    !loading.value
    && (houseEmpty.value
      || (street.value
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim().length
        && !responseList.value.address_objects.length
        && !responseList.value.houses.length))
  )
})
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const responseList = ref({
  address_objects: [] as BaseAddressObject[],
  houses: [] as House[],
})
const { clearRecentSearch, addRecentSearch } = recentSearchFn()

const fakeFn = () => {
  console.log(counter)
  console.log(textReplace)
}

const openDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'offer' })
}
const showDialogCity = () => {
  ctx.$event('cityDialog', { redirect: true, label: 'offer' })
}

const showMap = () => {
  ctx.$event('mapDialog', { label: 'offer', redirect: true })
}

const selectStreet = async (street) => {
  needFocus.value = false
  chooseAddress(street)
  showModalStreets.value = false
}

const streetAddress = computed(() =>
  currentStreet.value
    ? itemTitle(currentCity.value) + ' ' + itemTitle(currentStreet.value)
    : itemTitle(currentCity.value),
)

const setFocused = (val) => {
  if (val) {
    ctx.$event('gtmSendEvent', {
      event: 'trackEvent',
      category: 'address',
      action: 'open',
      label: 'offer',
    })
    const target = document.getElementById('sub-h__checkpc-textarea')
    scrollToBlock(target, 750, 100)
    focused.value = val
    if (!street.value) {
      if (props.streetBoolean && getAddressText.value.street)
        street.value = streetAddress.value + ' '
      else chooseAddress(currentCity.value)
    }
    else if (!houseUrl.value && currentStreet.value?.fias_id && !selectedStreet.value) chooseAddress(currentStreet.value)
    else if (street.value === streetAddress.value)
      street.value = streetAddress.value + ' '
    setTimeout(() => {
      addressRef.value?.setSelectionRange(
        street.value.length,
        street.value.length,
      )
      shadow.value = val
    }, 2)
  }
  else {
    loadingValidate.value = Math.random()
    nextTick(async () => {
      street.value = houseUrl.value
        ? getAddressText.value.houseAddress || ''
        : props.streetBoolean
          ? streetAddress.value
          : ''
      shadow.value = false
      setTimeout(() => {
        shadow.value = false
      }, 3)
      setTimeout(() => {
        focused.value = false
        responseList.value = {
          address_objects: [],
          houses: [],
        }
        loading.value = false
      }, 240)
      if (log.value.length) {
        await $fetch(`https://${mainStore.domain}/api/core/logs/`, {
          method: 'POST',
          headers: {
            ...getCommonHeaders.value,
          },
          body: {
            name: 'address_fail',
            at_url: 'https://' + domain.value + $route.fullPath,
            level: 'DEBUG',
            // "data": JSON.stringify(log.value)
            data: log.value,
          },
        })
      }
    })
  }
}
const showProviders = (val) => {
  if (houseUrl.value)
    $router.push({
      path: `/personal/address/${houseUrl.value}/`,
    })
  else if (val && addressRef.value) addressRef.value.focus()
  else openDialogAddress()
}
const input = (event) => {
  if (eventInput.value) {
    eventInput.value = false
    ctx.$event('gtmSendEvent', {
      event: 'trackEvent',
      category: 'address',
      action: 'typing',
      label: '',
    })
  }
  needFocus.value = true
  event.target.composing = false
  street.value = event.target.value
  loading.value = true
  loadingValidate.value = Math.random()
  debounceSearch()
}

const keyDown = (e) => {
  if (e.keyCode === 13) e.preventDefault()
}

const debounceSearch = debounce(() => {
  searchAddress(street.value)
}, 350)

const searchAddress = (queryString: string) => {
  if (!(selectedCity.value && queryString.includes(selectedCity.value.name))) {
    selectedCity.value = undefined
  }
  if (
    !(selectedStreet.value && queryString.includes(selectedStreet.value.name))
  ) {
    houseEmpty.value = false
    selectedStreet.value = undefined
    selectedHouse.value = undefined
    badCheck.value = false
  }

  if (
    !(selectedHouse.value && queryString.includes(selectedHouse.value.number))
  ) {
    selectedHouse.value = undefined
    badCheck.value = false
  }

  getSuggestionsMulti(
    queryString
      .replace(itemTitle(selectedCity.value), '')
      .replace(itemTitle(selectedStreet.value), '')
      .trim(),
    (cb) => {
      responseList.value
        = typeof cb === 'object'
        && !Array.isArray(cb)
        && cb !== null
        && shadow.value
          ? cb
          : {
              address_objects: [],
              houses: [],
            }
    },
  )
}

const getSuggestionsMulti = async (
  queryString: string,
  cb: (arg) => void,
  offset?: number,
) => {
  loadingMore.value = true
  const validate = Math.random()
  loadingValidate.value = validate
  try {
    const response = await $fetch(`https://${mainStore.domain}/api/suggestions/multi/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        query: queryString,
        tooltips_always: true,
        site_city_fias_id: currentCity.value?.fias_id,
        street_fias_id: selectedStreet.value?.fias_id,
        move_house_street_tooltip: true,
        limit: 20,
        offset,
      },
    })
    log.value.push({
      req: queryString,
      resp: response,
    })
    if (loadingValidate.value === validate) {
      enableInfinite.value = response.can_scroll
      totalItems.value = response.total_items
      const block = document.getElementsByClassName('sub-h__checkpc-res')?.[0]
      if (block && !offset) block.scrollTo(0, 0)
      cb(response)
      loadingMore.value = false
      loading.value = false
      loadingMoreSkeleton.value = false
      if (response.address_objects?.length || response.houses?.length)
        isError.value = false
      else isError.value = true
    }
  }
  catch (e) {
    isError.value = true
    loadingMore.value = false
    // eslint-disable-next-line standard/no-callback-literal
    cb([])
  }
}
const drag = debounce(() => {
  if (
    !loadingMore.value
    && enableInfinite.value
    && (selectedStreet.value
      ? responseList.value.houses.length < totalItems.value
      : responseList.value.address_objects.length < totalItems.value)
  ) {
    if (dragPopper.value) {
      if (dragPopper.value.scrollHeight > dragPopper.value.clientHeight) {
        const scrolledToBottom
          = dragPopper.value.scrollHeight - dragPopper.value.clientHeight
          <= dragPopper.value.scrollTop + 300
        if (scrolledToBottom) loadMore()
      }
    }
  }
}, 50)
const loadMore = () => {
  if (!loadingMore.value) {
    loadingMoreSkeleton.value = true
    getSuggestionsMulti(
      street.value
        .replace(itemTitle(currentCity.value), '')
        .replace(itemTitle(selectedCity.value), '')
        .replace(itemTitle(selectedStreet.value), '')
        .trim(),
      addMore,
      selectedStreet.value
        ? responseList.value.houses.length
        : responseList.value.address_objects.length,
    )
  }
}
const addMore = (val) => {
  responseList.value.houses.push(...val.houses)
  responseList.value.address_objects.push(...val.address_objects)
}

const itemTitle = (street?: BaseAddressObject) => {
  return street ? `${street.short_name} ${street.name}` : ''
}

const addressWithStrong = (title: string) => {
  if (!street.value) return title
  const words = street.value.trim().split(/[^0-9а-яА-ЯёЁ]+/gmi).filter(Boolean).sort((a, b) => b.length - a.length)
  let newTitle = title
  const regexList = words.map(word => new RegExp(`(?!<strong[^>]*?>)(${word})(?![^<]*?</strong>)`, 'giu'))

  regexList.forEach((regex) => {
    newTitle = newTitle.replace(regex, match => `<strong class='cl-pr--t'>${match}</strong>`)
  })
  return newTitle
}

const clearStreet = async () => {
  street.value = ''
  if ($route.name === 'city-streets-street') {
    addressRef.value.focus()
    street.value = ''
    selectedStreet.value = undefined
    return
  }
  let res

  houseEmpty.value = false
  selectedStreet.value = undefined
  selectedCity.value = undefined
  selectedHouse.value = undefined
  badCheck.value = false
  nextTick(() => {
    if (needFocus.value && addressRef.value) addressRef.value.focus()
  })

  if ($route.name === 'city-not-available') {
    $router.push({ path: `/${currentCity.value?.url}/` })
    return
  }

  try {
    res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${currentCity.value?.fias_id}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch {}

  cityStore.$patch({
    addressAncestors: res,
  })
  cityStore.house = {}
  tariffsStore.passThroughProviderId = null

  const coockieAddress = useCookie('currentAddress')
  coockieAddress.value = ''
}
const focusHome = () => {
  nextTick(() => {
    street.value += ' '
    if (needFocus.value && addressRef.value) addressRef.value.focus()
    responseList.value = {
      address_objects: [],
      houses: [],
    }
  })
}

const chooseAddress = (st) => {
  if (st.level === 'city' && st.fias_id !== currentCity.value?.fias_id) {
    const currentCityUrl = currentCity.value?.url
    selectedCity.value = st
    nextTick(async () => {
      street.value = itemTitle(st)
      selectedHouse.value = undefined
      selectedStreet.value = undefined
      badCheck.value = false
      focusHome()
      let res
      try {
        res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${st.fias_id}/ancestors/`, {
          method: 'GET',
          headers: {
            ...getCommonHeaders.value,
          },
          params: {
            include_self: true,
            morph: true,
          },
        })
      }
      catch (e) {
      }
      finally {
      }
      cityStore.$patch({
        addressAncestors: res,
      })
      cityStore.house = {}
      tariffsStore.passThroughProviderId = null

      const coockieCity = useCookie('currentCity')
      const coockieAddress = useCookie('currentAddress')
      coockieCity.value = selectedCity.value.fias_id
      coockieAddress.value = ''
      if ($route.name.toString().startsWith('city-')) {
        history.replaceState(
          { page: 1 },
          '',
          window.location.href
            .toString()
            .replace(currentCityUrl, selectedCity.value.url),
        )
      }
    })
  }
  else if (st.level === 'city' || st.fias_id === currentCity.value?.fias_id) {
    selectedCity.value = st
    // nextTick(async () => {
    street.value = itemTitle(st)
    selectedHouse.value = undefined
    selectedStreet.value = undefined
    badCheck.value = false
    focusHome()
    // })
  }
  else {
    selectedStreet.value = st

    updateStreet.value = true
    loading.value = true

    nextTick(() => {
      street.value
        = (selectedCity.value ? itemTitle(selectedCity.value) + ' ' : '')
        + itemTitle(st)
      selectedHouse.value = undefined
      badCheck.value = false
      searchAddress(street.value)
      focusHome()
    })
  }
}

const sendGtm = (action, value) => {
  if ($gtm) {
    $gtm.trackEvent({
      category: '',
      event: 'trackEvent',
      action,
      value,
    })
  }
}

const chooseHouse = (st) => {
  selectedHouse.value = st
  nextTick(() => {
    street.value = st.parents_tooltip
      ? st.parents_tooltip + ' ' + st.number
      : (selectedCity.value ? itemTitle(selectedCity.value) + ' ' : '')
        + itemTitle(selectedStreet.value)
        + ' '
        + st.number
    houseEmpty.value = false
  })
  changeAddress()
}
const chooseRecentHouse = (st) => {
  selectedHouse.value = st.selectedHouse
  nextTick(() => {
    street.value = st.name
    houseEmpty.value = false
  })
  changeAddress()
}
const { getAddresLoc } = getAddresLocation()
const changeAddress = () => {
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'confirmed',
    label: '',
  })
  nextTick(async () => {
    log.value = []
    let res
    let loc
    try {
      const promiseLoc = async () => {
        loc = await getAddresLoc({
          url: selectedHouse.value!.autogenerated_url,
          houseId: selectedHouse.value!.id,
          houseFiasId: selectedHouse.value!.dadata?.fias_id,
          streetFiasId:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.parent_fias_id
              : undefined,
          houseNumber:
            selectedHouse.value!.dadata?.house
            && selectedHouse.value!.dadata?.parent_fias_id
              ? selectedHouse.value!.dadata?.house
              + (selectedHouse.value!.dadata?.block_type
                ? ' ' + selectedHouse.value!.dadata?.block_type
                : '')
              + (selectedHouse.value!.dadata?.block
                ? ' ' + selectedHouse.value!.dadata?.block
                : '')
              : undefined,
        })
      }
      const promiseRes = async () => {
        if (selectedHouse.value!.dadata?.parent_fias_id) {
          res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${selectedHouse.value!.dadata?.parent_fias_id}/ancestors/`, {
            method: 'GET',
            headers: {
              ...getCommonHeaders.value,
            },
            params: {
              include_self: true,
              morph: true,
            },
          })
        }
      }

      mainStore.$patch({
        showLoader: true,
      })

      await promiseLoc()
      await promiseRes()
    }
    catch (e) {
      ctx.$sentryCaptureMessage(e)
      // result = []
      // showLoader: false
    }
    finally {
      selectedCity.value = res.address_objects.find(
        el => el.level === 'city',
      )
      if (!selectedCity.value)
        selectedCity.value = res.address_objects.find(
          el => el.level === 'street',
        )
      else
        selectedStreet.value = res.address_objects.find(
          el => el.level === 'street',
        )

      cityStore.$patch({
        addressAncestors: res,
      })

      addRecentSearch({
        name: street.value,
        houseUrl: loc.autoGeneratedUrl,
        parents_tooltip:
          (selectedCity.value?.short_name
            ? selectedCity.value.short_name + ' '
            : '') + selectedCity.value?.name,
        selectedHouse: selectedHouse.value,
      })

      const coockieAddress = useCookie('currentAddress')

      coockieAddress.value = selectedHouse.value!.autogenerated_url
      mainStore.$patch({
        oldClient: false,
        providerClient: undefined,
      })
      oldClientCookie.value = null
      providerClientCookie.value = null

      if (selectedCity.value) {
        const coockieCity = useCookie('currentCity')
        coockieCity.value = selectedCity.value.fias_id
      }

      sendGtm('changeAddress', selectedHouse.value)

      $router.push({
        path: `/personal/address/${loc.autoGeneratedUrl}/`,
      })
    }
  })
}

watch(
  () => houseUrl.value,
  () => {
    if (!houseUrl.value) street.value = ''
  },
)

onMounted(() => {
  nextTick(() => {
    mounted.value = true
    if (houseUrl.value) {
      street.value = getAddressText.value.houseAddress
    }
    if (props.streetBoolean) {
      selectedStreet.value = JSON.parse(JSON.stringify(currentStreet.value))
      nextTick(() => (street.value = streetAddress.value))
    }
  })
})
</script>

<style lang="scss" scoped>
.sub-h {
  width: 100vw;
  background: color(gradient);
  padding-bottom: 64px;
  position: relative;

  .mask-trash {
    mask-image: url('@/assets/images/svg/trash.svg');
  }
  &__wrap {
    position: static;
    //z-index: 1;
  }

  &__check {
    cursor: pointer;
    border-radius: 48px;
    //border: 2px solid color(primary);
    overflow: hidden;
    transition: width 1s;
    width: 100%;
    max-width: 100%;
    position: relative;
    height: 56px;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25);

    &-bg {
      background: color(gray-p);
      border-radius: 28px;
      border: 1px solid color(gray-darker);
    }

    &-text {
      margin-left: 16px;
      font-size: fontSize(title);
      line-height: 1.4;

      img {
        margin-right: 4px;
        width: 32px;
        height: 32px;
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 28px;
          height: 28px;
        }
      }
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(title);
        line-height: 1.5;
        margin-left: 16px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 15px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
        margin-left: 12px;
      }
    }
    &--btn {
      position: absolute;
      right: 3px;
      top: 3px;
      margin-left: auto;
      width: 44px;
      height: 44px;
      line-height: 1.4;
      border-radius: 28px;
      img {
        width: 24px;
        height: 24px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        height: 48px;
        width: 48px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 44px;
        width: 44px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
        width: 40px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      height: 54px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 48px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 46px;
    }
  }

  &__checkpc {
    display: flex;
    width: 100%;
    //z-index: 301;
    margin-bottom: 32px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    overflow: hidden;
    border-radius: 37px;
    transition: border 0.24s;
    @media (max-width: getBreakpoint(tablet)) {
      top: 40px;
    }

    &-bg {
      width: 100%;
      height: 74px;
      max-width: 860px;
      &:before {
        content: none;
        position: fixed;
        background: rgba(color(black), 0);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: background 0.24s;
      }
      &.active {
        &:before {
          content: "";
          z-index: 300;
        }
      }
      &.shadow {
        .sub-h__checkpc-textarea {
          padding-top: 32px;
        }
        &:before {
          background: rgba(color(black), 0.7);
        }
      }
    }
    &--btn {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 4px;
      padding: 18px 40px;
      border-radius: 48px;
      font-size: fontSize(headline-7);
      line-height: 1.4;
      opacity: 1;
      transition: opacity 0.24s;
      &.hide {
        opacity: 0;
      }
    }
    &.animation {
      animation: focus 1.5s;
      animation-iteration-count: infinite;
    }
    &.is-focus {
      box-shadow: 0px 0px 10px rgba(2, 4, 30, 0.1);
    }
    &.active {
      z-index: 301;
    }
    &-search {
      width: 100%;
    }
    &-tooltip {
      position: absolute;
      left: 69px;
      top: 8px;
    }
    &-pin {
      position: absolute;
      top: 20px;
      left: 19px;
      width: 32px;
      height: 32px;
    }
    &-textarea {
      height: 72px;
      width: 100%;
      border-radius: 50px;
      padding: 19px 54px;
      background-color: white;
      border: none;
      transition: padding-top 0.05s;
      font-size: fontSize(headline-8);

      &::placeholder {
        color: color(gray-dark);
      }
      &-popper {
      }
    }
    &-clear {
      position: absolute;
      right: 21px;
      top: 21px;
      cursor: pointer;
      transition: right 0.24s;
      height: 32px;
      width: 32px;
      &.active {
        right: 304px;
      }
    }
    &-popper {
      overflow: hidden;
      position: relative;
      background: color(white);
      min-height: 0;
      max-height: 0;
      transition-property: max-height, min-height;
      transition-duration: 0.14s;
      &.show {
        max-height: 378px;
        min-height: 378px;
        &:before {
          content: "";
          position: absolute;
          left: 24px;
          right: 24px;
          top: 0;
          height: 1px;
          background-color: color(gray-darker);
        }
      }
    }
    &-res {
      overflow-y: auto;
      padding: 12px 20px 0 69px;
      margin-right: 4px;
      margin-bottom: 12px;
    }

    &-loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 1px;
      bottom: 0;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      &:deep(svg) {
        will-change: transform;

        path:not(:nth-child(3)) {
          transform-origin: center;
          animation-name: path-animation;
          animation-iteration-count: infinite;
          // animation-direction: alternate;
          animation-duration: 1s;
          transform: scale(0);
        }

        path:nth-child(1) {
          animation-delay: 0.5s;
        }
      }
    }
    &-btn {
      width: 100%;
      height: 57px;
      min-height: 57px;
      font-size: fontSize(headline-7);

      &-title:deep(strong) {
        color: color(primary);
        font-weight: 400;
      }

      &-icon {
        min-width: 24px;
        position: absolute;
        left: -38px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__tips {
    padding: 12px 24px;
  }

  &__recent {
    overflow-y: auto;
    & > p {
      margin-bottom: 8px;
    }
    .sub-h__checkpc-btn {
      width: unset;
      padding-left: 32px;
      border-bottom: none;
      min-height: 40px;
      height: 40px;
      margin-right: auto;
      .d-icon {
        left: -32px;
        &:deep(svg) {
          width: 24px;
          height: 24px;
        }
      }
      .secondary-dark--text {
        transition: color 0.24s;
      }
      &:hover .secondary-dark--text {
        color: color(primary);
      }
    }

    &-clear {
      position: absolute;
      right: 16px;
      bottom: 12px;
      transition: color 0.24s;
      .mask-icon {
        margin-right: 8px;
      }
      &:hover {
        color: color(black);
      }
    }
  }

  &__headline {
    margin-bottom: 28px;
    margin-top: 40px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 0;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-top: 12px;
      margin-bottom: 24px;
    }
    h1 {
      color: color(white);
      white-space: pre-wrap;
      & > span {
        display: flex;
        align-items: center;
        margin-top: 4px;
        @media (max-width: getBreakpoint(tablet)) {
          display: inline-flex;
          margin-top: 0;
        }
      }

      & > span {
        margin-top: 0;
        display: flex;
        @media (max-width: getBreakpoint(tablet)) {
          display: inline-flex;
        }
        .pointer {
          position: relative;
          text-decoration: underline;
          text-decoration-color: color(primary);
          margin-right: auto;
        }
      }

      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 0;
        margin-top: 36px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-top: 24px;
      }
    }
  }

  &__benefits {
    opacity: 0.7;
    margin-bottom: -16px;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      margin-bottom: -44px;
    }
    &-item {
      padding-left: 40px;
      position: relative;
      max-width: 284px;
      margin-right: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        padding-left: 36px;
        max-width: unset;
        margin-bottom: 24px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        padding-left: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding-left: 28px;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        @media (max-width: getBreakpoint(tablet)) {
          top: -8px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 28px;
          height: 28px;
          top: -6px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 24px;
          height: 24px;
          top: -4px;
        }
      }
    }
    &-title {
      margin-top: 2px;
      margin-bottom: 8px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__promo-text {
    color: color(white);
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
  }

  &__main {
    margin-bottom: auto;

    @media (max-width: getBreakpoint(desktop)) {
      width: 100%;
    }
  }

  &__showMap {
    margin-left: auto;
    margin-top: 4px;
    margin-bottom: 40px;
    opacity: 0.7;
    transition: opacity 0.24s;
    &:hover {
      opacity: 1;
    }
    &-pin {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }
}
@keyframes path-animation {
  from {
    transform: scale(0);
    opacity: 0;
  }

  25% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  49% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
